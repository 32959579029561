.drag-area
{
    min-height: 250px;
    border: dashed 2px rgb(214, 214, 214);
    display: 'inline-block';
     position: relative !important
}
.drag-area.drag
{
    min-height: 250px;
    
    
}
.drag-area.drag>div
{
    display: none;
    
}
.drag-area.drag::before
{
    content: "Yüklemek istediğiniz resimleri bırakabilirsiniz";
    display: grid;
    text-align: center;
    position: absolute;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    color: white;
    opacity: .7;
}