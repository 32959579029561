@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    background-color: #F8F9FA;
    font-family: 'Poppins';
}

.card {
    box-shadow: #00000012 0px 0px 30px;
    border: none;
    border-radius: 1rem;
}

.card-header {
    border: none;
    background-color: transparent;
    font-weight: bold;
    font-size: 1.6rem;
}

.card-footer {
    border: none;
    background-color: transparent;
}

.btn {
    padding-left: 20px;
    padding-right: 20px;

    font-size: 0.8rem;
}

.btn-primary {
    border-radius: 20px;
    background-color: #FF511F;
    border: none;
    box-shadow: #00000030 0px 0px 5px;
}

.btn-outline-primary {
    border-radius: 20px;
    background-color: transparent;
    border-color: #FF511F;
    color: #FF511F;
    box-shadow: #00000030 0px 0px 5px;
}

.btn-outline-primary:hover {
    border-radius: 20px;
    background-color: #FF511F;
    border-color: #FF511F;
    box-shadow: #00000030 0px 0px 5px;
}

.compnay-logo {
    margin-top: -50px !important;
    border-radius: 500px;
    width: 140px;
    height: 140px;
    background-color: white;
    box-shadow: #00000020 0px 0px 40px;
}

.list-inline-item {
    padding-right: 10px;
    color: #DBDBDB;
    font-size: 0.8rem;
}

.list-inline-item.active {
    color: #FF511F;
    font-weight: bold;
}

ul {

    padding-bottom: 10px;
    border-bottom: #f1f1f1 dashed 2px;
}

table>tr>th {
    color: #DBDBDB;
    font-weight: bold;
    font-size: 0.8rem;
}

table>tr>td {
    color: #525252;
    height: 60px;
}

.react_times_button {
    background-color: #FF511F;
    border-radius: 50px;
    color: white;
    fill: white;
    padding: 0px;
}

.time_picker_preview {
    height: 40px;
}

.preview_container {
    line-height: 40px;
}

.preview_container svg {
    height: 30px;
    top: 5px;
}

.gradient-custom {
    /* fallback for old browsers */
    background: #6a11cb;

    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right, #ff511f, #ff997d);

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right, #ff511f, #fc9376)
}